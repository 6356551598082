import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Container, AppBar, Toolbar, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';
import config from '../../config.json';

const ChangePassword = () => {
    const [passwordOld, setPasswordOld] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('')
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('resetCode');
        const username = queryParams.get('username')
        console.log('134', code, username)
        if (code) {
            setPasswordOld(code); // เก็บค่า resetCode ที่มาจาก query string
        }
        if (username) {
            setUsername(username)
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordNew !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        if (passwordNew.length < 8) {
            setError('รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร');
            return;
        }

        try {
            // เรียก API สำหรับเปลี่ยนรหัสผ่าน (โค้ดนี้เป็นตัวอย่าง จำเป็นต้องใช้ฟังก์ชันจริงของคุณ)
            const response = await fetch( config.BASE_API + 'authen/changePassword_2', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password_old: passwordOld,
                    password_new: passwordNew,
                }),
            });

            const data = await response.json();
            console.log(data)
            if (data.response.status) {
                setSuccess(true); // เปลี่ยนสถานะเป็นสำเร็จเมื่อเปลี่ยนรหัสผ่านเสร็จแล้ว
            } else {
                alert('Failed to change password');
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };

    if (success) {
        return (
            <Container maxWidth="sm">
                   <AppBar position="fixed" style={{ background: '#76BC43' }}>
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, backgroundColor: '#76BC43' }}
                    >
                        ระบบจัดการข้อมูลผู้ใช้
                    </Typography>

                </Toolbar>

            </AppBar>
                <Box
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5" gutterBottom style={{ marginTop:20}}>
                        เปลี่ยนรหัสผ่านเสร็จแล้ว
                    </Typography>
                    <Typography>
                        คุณได้ทำการเปลี่ยนรหัสผ่านของคุณสำเร็จแล้ว โปรดเข้าสู่ระบบด้วยรหัสผ่านใหม่
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <AppBar position="fixed" style={{ background: '#76BC43' }}>
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, backgroundColor: '#76BC43' }}
                    >
                        ระบบจัดการข้อมูลผู้ใช้
                    </Typography>

                </Toolbar>
            </AppBar>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    เปลี่ยนรหัสผ่าน
                </Typography>

                <Alert severity="warning">
                    ด้วยเหตุผลด้านความปลอดภัยของข้อมูล กรมการข้าว ขอให้ท่านผู้ใช้งานเปลี่ยนรหัสผ่านใหม่
                    โดยรหัสผ่านใหม่ต้องมีความยาวอย่างน้อย 8 ตัวอักษรขึ้นไป
                </Alert>


                {error && (
                    <Typography color="error" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}

                <TextField
                    label="รหัสผ่านใหม่"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={passwordNew}
                    onChange={(e) => setPasswordNew(e.target.value)}
                    inputProps={{ minLength: 8 }}
                    required
                />

                <TextField
                    label="ยืนยันรหัสผ่านใหม่"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />


                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    เปลี่ยนรหัสผ่าน
                </Button>
            </Box>
        </Container>
    );
};

export default ChangePassword;
