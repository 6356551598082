import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  useLocation
} from 'react-router-dom';

import {
  callService,
  getService,
  updateService,
  userDetail
} from '../services/Service'



const UserForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [menu_list, setMenuList] = useState([])
  const [data, setData] = useState('')
  const [isBusy, setBusy] = useState(mode === "add" ? false : true)
  const [list_user, setListUser] = useState()
  const [list_app_user, setListAppUser] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (mode === "edit") {
      createAppUserList()
      createUser()
      setButtonTitle("แก้ไขแอปพลิเคชัน")
      let app_id = location.pathname.split('/')[3]
      getService('applications/detail/' + app_id)
        .then(res => {
          setData(res.data.data)
          createMenuList(res.data.data.menu_list)
          setBusy(false)
        })
    } else {
      setButtonTitle("เพิ่มแอปพลิเคชัน")
    }
  }, []);

  const createUser = () => {
    getService('users')
      .then(res => {
        let {data} = res.data.data
        let list = []
        data.map((item, index) => {
          list.push({
            'index': index + 1,
            'id': item.user_id,
            'username': item.username
          })
        })
        setListUser(list)
      })
  }

  const createAppUserList = () => {
    let param = location.pathname.split('/')[3]
    userDetail('applications/users/get', { app_id: param })
      .then(res => {
        let data = res.data.data
        let list = []
        data.map((item, index) => {
          list.push({
            'index': index + 1,
            'id': item.user_id,
            'username': item.username,
            'first_name': item.first_name + ' ' + item.last_name
          })
        })
        setListAppUser(list)
      })
  }

  const columns = [
    { field: 'index', headerName: 'ลำดับ', maxWidth: 100, flex: 1 },
    { field: 'id', headerName: 'ID', minWidth: 250, flex: 1 },
    { field: 'username', headerName: 'ชื่อบัญชีผู้ใช้', minWidth: 250, flex: 1 },
    { field: 'first_name', headerName: 'ชื่อ-นามสกุล', minWidth: 250, flex: 1 },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          sx={{ color: '#76BC43' }}
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ]

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault()
    let user_id = cellValues.row.id
    let app_id = location.pathname.split('/')[3]
    let payload = {
      'user_id': user_id,
      'app_id': parseInt(app_id)
    }
    userDetail('applications/users/delete', payload)
      .then(res => {
        if (res.status === 200) {
          createAppUserList()
          alert('ลบ ผู้ใช้งาน ออกจาก แอปพลิเคชัน สำเร็จแล้ว')
        }
      })
  }


  const createMenuList = (obj) => {
    let all_menu = obj
    let temp_menu_list = [];
    all_menu.map((item, index) => {
      temp_menu_list.push({
        id: item.menu_id,
        index: index + 1,
        menu_name: item.menu_name,
        menu_type: item.menu_type,
      })
    })
    setMenuList(temp_menu_list);
  }

  const handleBackButton = () => window.location.href = "/application/list";;

  const handleAddUserClick = () => {
    let app_id = location.pathname.split('/')[3]
    let payload = {
      'app_id': parseInt(app_id),
      'user_id': inputValue.id
    }
    userDetail('applications/users/create', payload).then
      (res => {
        if (res.status === 200) {
          createAppUserList()
          setOpen(false)
        }
      })
  }

  const handleSaveButton = () => {
    if (data.app_name === "" || null) {
      alert("คุณยังไม่ระบุ ชื่อ \n กรุณาเพิ่ม ชื่อ ")
    }
    else {
      if (mode === "add") {
        let object = {
          "app_name": data.app_name,
          "menu_list": menu_list
        }
        callService('applications/create', object)
          .then(res => {
            alert("เพิ่มแอปพลิเคชันสำเร็จ")
            window.location.href = "/application/list";
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        let app_id = location.pathname.split('/')[3]
        let object = {
          "app_id": app_id,
          "app_name": data.app_name,
          "menu_list": menu_list[0]
        }
        updateService('applications/update/' + app_id  , object)
          .then(res => {
            alert("แก้ไขแอปผพลิเคชันสำเร็จ")
            window.location.href = "/application/list";
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Card style={{ margin: '1.5rem 15%' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-name"
                  label="ชื่อ"
                  variant="outlined"
                  value={data.app_name}
                  onChange={event => setData({ ...data, app_name: event.target.value })}

                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <Grid item xs>
                <p style={{ marginLeft: 10 }}>แก้ไขล่าสุด: {data.updated_date}</p>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSaveButton}
                >
                  {button_title}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleBackButton}
                >
                  ย้อนกลับ
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        {mode === 'edit' && (
          <Card style={{ margin: '1.5rem 15%' }}>
            <CardActions>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"

                    onClick={() => { setOpen(true) }}
                    sx={{
                      fontSize: 20,
                      backgroundColor: '#76BC43',
                      '&:hover': {
                        backgroundColor: '#5e9e36',
                      },

                    }}
                  >
                    เพิ่มผู้ใช้
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            <div style={{ height: 400, width: '95%', paddingBottom: '2rem', marginTop: '2rem', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={columns}
                rows={list_app_user}
              />
            </div>
          </Card>
        )}
        <Dialog open={open} onClose={() => { setOpen(false) }} fullWidth>
          <DialogTitle>เลือกผู้ใช้</DialogTitle>
          <DialogContent>
            <Autocomplete
              options={list_user}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => (
                <TextField {...params} label="เลือกผู้ใช้" variant="outlined" fullWidth />
              )}
              onChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              sx={{ color: 'white', backgroundColor: 'green', fontSize: 20 }}
              onClick={handleAddUserClick}>ตกลง</Button>
            <Button
              variant='contained'
              sx={{
                color: 'white',
                backgroundColor: 'red',
                fontSize: 20
              }}
              onClick={() => { setOpen(false) }}>
              ยกเลิก
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
export default UserForm;