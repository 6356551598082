import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DataGrid } from '@mui/x-data-grid';
import { Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  useLocation
} from 'react-router-dom';

import {
  callService,
  getOrg,
  userDetail,
  getApp,
  updateService,
  getService
} from '../services/Service'
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Label } from '@mui/icons-material';

const UserForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [selected_group, setSelectedGroup] = useState(0)
  const [group_list, setGroupList] = useState([])
  const [org_list, setOrgList] = useState([])
  const [app_list, setAppList] = useState([])
  const [data, setData] = useState('')
  const [isBusy, setBusy] = useState(mode === "add" ? false : true)
  const [select_org, setSelectionOrg] = useState('')
  const [select_app, setSelectionApp] = useState('')
  const [list_app_user, setListAppUser] = useState([])
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [list_app, setListApp] = useState([])
  const [open_reset_pass, setOpenRePass] = useState(false)
  const [new_password, setNewpassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  useEffect(() => {
    createOrgList()
    if (mode === "edit") {
      createGroupList()
      createAppUserList()
      createApp()
      setButtonTitle("แก้ไขผู้ใช้")
      let user_id = location.pathname.split('/')[3]
      getService('users/detail/' + user_id)
        .then(res => {
          let user = res.data.data;
          setData(res.data.data)
          setSelectionOrg(user.org_id)
          setBusy(false)
        })
    } else {
      setButtonTitle("เพิ่มผู้ใช้")
    }
  }, []);

  const createApp = () => {
    getService('applications')
      .then(res => {
        if (res.status === 200) {
          let { data } = res.data.data
          let list = []
          data.map((item, index) => {
            list.push({
              'index': index + 1,
              'id': item.app_id,
              'app_name': item.app_name
            })
          })
          setListApp(list)
        }
      })
  }

  const createAppUserList = () => {
    let user_id = location.pathname.split('/')[3]
    let payload = {
      "user_id": user_id
    }
    callService('applications/user/getByUserId/', payload)
      .then(res => {
        if (res.status === 200) {
          let data = res.data.data
          let list = []
          data.map((item, index) => {
            list.push({
              'index': index + 1,
              'id': item.app_id,
              'app_name': item.app_name
            })
          })
          setListAppUser(list)
        }
      })
  }

  let columns = [
    { field: 'index', headerName: 'ลำดับ', maxWidth: 100, flex: 1 },
    { field: 'id', headerName: 'Application Id', minWidth: 250, flex: 1 },
    { field: 'app_name', headerName: 'ชื่อ', minWidth: 250, flex: 1 },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      maxWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ]

  const handleAddUserClick = () => {
    let user_id = location.pathname.split('/')[3]
    let payload = {
      'app_id': inputValue.id,
      'user_id': parseInt(user_id),
    }
    callService('applications/users/create', payload).then
      (res => {
        if (res.status === 200) {
          createAppUserList()
          setOpen(false)
        }
      })
  }


  const handleDeleteClick = (event, cellValues) => {
    console.log('delete')
    event.preventDefault()
    let app_id = cellValues.row.id
    let user_id = location.pathname.split('/')[3]
    let payload = {
      'user_id': user_id,
      'app_id': parseInt(app_id)
    }
    console.log(payload)
    callService('applications/users/delete', payload)
      .then(res => {
        if (res.status === 200) {
          createAppUserList()
          alert('ลบ user ออกจาก application สำเร็จแล้ว')
        }
      })
  }





  const createOrgList = () => {
    getService('organizations/get')
      .then(res => {
        let list = []
        let { data } = res.data.data
        data.map((item) => {
          list.push({
            id: item.org_id,
            label: `${item.org_code} : ${item.org_name}`
          })
        })
        setOrgList(list)
      })
  }


  const createGroupList = () => {
    let params = {
      "org_id": 0,
      "search": '',
      "isActive": 'A'
    }
    callService('organizations/group/get', params)
      .then(res => {
        let group_list = []
        let data = res.data.data
        data.map((item) => {
          group_list.push({
            id: item.group_id,
            name: item.group_name,
            org_id: item.org_id
          })
        })
        setGroupList(group_list)
      })
  }
  const handleBackButton = () => window.location.href = "/user/list";;

  const handleSaveButton = () => {
    if (data.username === "" || null) {
      alert("คุณยังไม่ระบุ Username \n กรุณาเพิ่ม Username")
    } else if (data.password === "" || null) {
      alert("คุณยังไม่ระบุ Password \n กรุณาเพิ่มPassword")
    } else if (data.first_name === "" || null) {
      alert("คุณยังไม่ระบุชื่อ \n กรุณาเพิ่มชื่อ")
    } else if (data.last_name === "" || null) {
      alert("คุณยังไม่ระบุนามสกุล \n กรุณาเพิ่มนามสกุล")
    } else {
      if (mode === "add") {
        let object = {
          "username": data.username,
          "password": data.password,
          "first_name": data.first_name,
          "last_name": data.last_name,
          "org_id": select_org
        }

        console.log(object)
        // console.log(object)
        callService('users/create', object)
          .then(res => {
            alert("เพิ่มผู้ใช้งานสำเร็จ")
            window.location.href = "/user/list";
          })
          .catch(error => {
            let data = error.response.data
            console.log(data)
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              } else if (error.response.status === 403) {
                alert('คุณไม่มีสิทธิสมัครสมาชิกในแอพพลิเคชั่นนี้')
              } if (error.response.status === 400) {
                alert('username ต้อง a-z, . , _  และต้องมี 6-8 ตัวอักษร :\n' + data.response.message)
              }
            }
          })
      } else {
        let user_id = location.pathname.split('/')[3]
        // let group_id = selected_group
        // let group_orgID = group_list.find(function (item) { return item.id == group_id })
        // let org_id = group_orgID.org_id
        let object = {
          "username": data.username,
          "first_name": data.first_name,
          "last_name": data.last_name,
          "org_id" : select_org,
          // "app_id" : select_app
        }
        updateService('users/update/' + user_id, object)
          .then(res => {
            alert("แก้ไขผู้ใช้งานสำเร็จ")
            window.location.href = "/user/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
              if (error.response.status === 403) {
                alert('คุณไม่มีสิทธิในแอพพลิเคชั่นนี้')
              }
            }
          })
      }
    }
  }
  const handleResetPassword = () => {
    let user_id = location.pathname.split('/')[3]
    let object = {
      "user_id": user_id,
      "new_password": new_password
    }
    callService('authen/resetPassword', object)
      .then(res => {
        alert("แก้ไขรหัสผ่านสำเร็จ")
        window.location.href = "/user/list";
      })
      .catch(error => {
        console.log(error.response);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/auth/login";
          }
          if (error.response.status === 403) {
            alert('คุณไม่มีสิทธิในแอปพลิเคชันนี้')
          }
        }
      })



  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (new_password !== confirmPassword) {
      setError(true);
    } else {
      setError(false);
      handleResetPassword()
    }
  };
  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Card style={{ margin: '1.5rem 15%' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                <TextField
                  fullWidth
                  id="outlined-username"
                  label="ชื่อบัญชีผู้ใช้"
                  variant="outlined"
                  value={data.username}
                  onChange={event => setData({ ...data, username: event.target.value })}
                />
              </Grid>
              {mode !== 'edit' && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-passsword"
                    label="รหัสผ่าน"
                    variant="outlined"
                    type="password"
                    value={data.password}
                    onChange={event => setData({ ...data, password: event.target.value })}
                  />
                </Grid>)}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-name"
                  label="ชื่อ"
                  variant="outlined"
                  value={data.first_name}
                  onChange={event => setData({ ...data, first_name: event.target.value })}

                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-surname"
                  label="นามสกุล"
                  variant="outlined"
                  value={data.last_name}
                  onChange={event => setData({ ...data, last_name: event.target.value })}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-org_id-label">หน่วยงาน</InputLabel>
                  <Select
                    labelid="select-org_id-label"
                    id="select-org_id"
                    value={select_org}
                    label="หน่วยงาน"
                    placeholder='องค์กร'
                    fullWidth
                    onChange={event => setSelectionOrg(event.target.value)}
                  >
                    <MenuItem key={0} value={0}><em>กรุณาเลือกองค์กร</em></MenuItem>
                    {org_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>

          <CardActions>
            <Grid
              container
              spacing={2}
              direction="row"
              // justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs>
                {/* <p style={{marginLeft:10}}>Last Edit: {data.updated_date.split('T')[0]}</p> */}
                <p style={{ marginLeft: 10 }}>แก้ไขล่าสุด: {data.updated_date}</p>
              </Grid>
              {mode === "edit" && (
                <Grid item >
                  <Button variant="contained" style={{ backgroundColor: '#3333FF' }} onClick={() => { setOpenRePass(true) }}>แก้ไขรหัสผ่าน</Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSaveButton}
                >
                  {button_title}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleBackButton}
                >
                  ย้อนกลับ
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        {mode === 'edit' && (
          <Card style={{ margin: '1.5rem 15%' }}>
            <CardActions>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => { setOpen(true) }}
                    sx={{
                      fontSize: 20,
                      backgroundColor: '#76BC43',
                      '&:hover': {
                        backgroundColor: '#5e9e36',
                      }
                    }}
                  >
                    เพิ่มแอปพลิเคชัน
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            <div style={{ height: 400, width: '95%', paddingBottom: '2rem', marginTop: '2rem', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={columns}
                rows={list_app_user}
              />
            </div>
          </Card>
        )}
        <Dialog open={open_reset_pass} onClose={() => { setOpenRePass(false) }} fullWidth>
          <DialogTitle>แก้ไขรหัสผ่าน</DialogTitle>
          <DialogContent>
            <Grid item xs={6} style={{ margin: 10 }}>
              <TextField
                fullWidth
                id="outlined-passsword"
                label="รหัสผ่านใหม่"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={new_password}
                onChange={event => setNewpassword(event.target.value)}
                error={error}
                helperText={error ? 'Passwords do not match' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ margin: 10 }}>
              <TextField
                fullWidth
                id="outlined-passsword"
                label="ยืนยันรหัสผ่านใหม่"
                variant="outlined"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={error}
                helperText={error ? 'Passwords do not match' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              sx={{ color: 'white', backgroundColor: 'green', fontSize: 20 }}
              onClick={handleSubmit}>บันทึก</Button>
            <Button
              variant='contained'
              sx={{
                color: 'white',
                backgroundColor: 'red',
                fontSize: 20
              }}
              onClick={() => { setOpenRePass(false) }}>
              ยกเลิก
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={() => { setOpen(false) }} fullWidth>
          <DialogTitle>เลือกแอปพลิเคชัน</DialogTitle>
          <DialogContent>
            <Autocomplete
              options={list_app}
              getOptionLabel={(option) => option.app_name}
              renderInput={(params) => (
                <TextField {...params} label="เลือก Application" variant="outlined" fullWidth />
              )}
              onChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              sx={{ color: 'white', backgroundColor: 'green', fontSize: 20 }}
              onClick={handleAddUserClick}>ตกลง</Button>
            <Button
              variant='contained'
              sx={{
                color: 'white',
                backgroundColor: 'red',
                fontSize: 20
              }}
              onClick={() => { setOpen(false) }}>
              ยกเลิก
            </Button>
          </DialogActions>
        </Dialog>
      </>

    )
  }
}
export default UserForm;